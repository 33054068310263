/**
 * @class  JcAutocomplete
 * @param  {HTMLElement} input
 * @param  {Object} [options]
 */
function JcAutocomplete(input, options) {
    if (!(input && input.nodeType && input.nodeType === 1)) {
        throw `JcAutocomplete: \`input\` must be HTMLElement, and not ${ {}.toString.call(input) }`;
    }

    // Bind all private methods
    for (let fn in this) {
        if (fn.charAt(0) === '_' && typeof this[fn] === 'function') {
            this[fn] = this[fn].bind(this);
        }
    }

    const defaults = {
        minChars: 3,
        delay: 150,
        offsetLeft: 0,
        offsetTop: 1,
        changeValue: true,
        containerClass: 'autocomplete-dropdown',
        itemClass: 'autocomplete-item',
        createContainer: function () {
            const container = document.createElement('div');

            container.style.display = 'none';
            container.classList.add(this.containerClass);

            document.body.appendChild(container);

            return container;
        },
        handleSelectItem: function (item) {

        },
        // eslint-disable-next-line no-unused-vars
        source: function (term, response) {
        },
        renderItem: function (item, search) {
            search = search.replace(/[^\w\s]/g, '\\$&');
            const re = new RegExp(`(${ search.split(' ').join('|') })`, 'gi');

            return `<div class="${ this.itemClass }" data-val="${ item.replace(/"/gi, '&quot;') }">${ item.replace(re, '<mark>$1</mark>') }</div>`;
        },

        renderHistory: function (item,search) {

        },
        renderCategory: function (item,search) {

        },
        // eslint-disable-next-line no-unused-vars
        onSelect: function (event, term, item) {

        },

        onChangeValue: function(event, term){

        },

        renderBlank: function (search) {

        }
    };

    this.input = input;
    this.options = options = _extend(defaults, options);

    const container = this.container = options.createContainer();

    if (!(container && container.nodeType && container.nodeType === 1)) {
        throw `JcAutocomplete: \`createContainer()\` must return HTMLElement, and not ${ {}.toString.call(container) }`;
    }

    if (container.parentNode === null) {
        throw 'JcAutocomplete: `createContainer()` must append `container` to the page';
    }

    this.container.hide = function () {
        this.style.display = 'none';
        this.visible = false;
    };

    this.container.show = function () {
        this.style.display = 'block';
        this.visible = true;
    };

    input.autocompleteReserve = input.getAttribute('autocomplete');
    input.autocomplete = 'off';

    input.lastValue = '';

    _on(window, 'resize', this._updateContainer);
    _on(window, 'scroll', this._updateContainer);
    _on(input, 'blur', this._blurHandler);
    _on(input, 'keydown', this._keydownHandler);
    _on(input, 'keyup', this._keyupHandler);

    if (!options.minChars) {
        _on(input, /*'focus'*/'click', this._focusHandler);
    }

    _live(`.${ options.itemClass }`, 'mouseover', function (event, context) {
        const selected = context.querySelector(`.${ options.itemClass }.selected`);

        if (selected) {
            selected.classList.remove('selected');
        }

        this.classList.add('selected');
    }, this.container);

    _live(`.${ options.itemClass }`, 'click', function (event, context) {
        let value = this.dataset.val;

        if (options.changeValue === true) {
            input.value = value;
        }

        input.lastValue = '';

        options.onSelect(event, value, this);
        context.hide();
    }, this.container);
}

JcAutocomplete.prototype = {
    constructor: JcAutocomplete,
    container: null,
    _suggest: function (data) {
        let { input, options } = this;
        let { value } = input;
        //console.log('data');
        if (data && value.length >= options.minChars) {
            let itemsString = '';

            //console.log('data 1', data?.history.length);

            // наполняем хистори
            if(data?.history?.length){
                for (let i = 0; i < data.history.length; i++) {
                    //console.log(options.renderHistory);
                    itemsString += options.renderHistory(data.history[i], value);

                }
            }

            //console.log('data 2');

            // наполняем данные
            if(data?.data?.length){
                for (let i = 0; i < data.data.length; i++) {
                    itemsString += options.renderItem(data.data[i], value);
                }
            }

            //console.log('data 3');

            // наполняем категории
            if(data?.items_category?.length){
                for (let i = 0; i < data.items_category.length; i++) {
                    itemsString += options.renderCategory(data.items_category[i], value);
                }
            }

            //console.log('data 4');

            this.container.innerHTML = itemsString;
            this._updateContainer(0);

            //console.log('data 5');

        } else {
            //console.log('hide no data');
            this.container.hide();
        }
    },
    _updateContainer: function (resize, next) {
        let { input, options, container } = this;

        let rect = input.getBoundingClientRect();

        if (container.style.position !== 'static') {
            container.style.left = `${ Math.round(rect.left + (window.pageXOffset || document.documentElement.scrollLeft) + options.offsetLeft) }px`;
            container.style.top = `${ Math.round(rect.bottom + (window.pageYOffset || document.documentElement.scrollTop) + options.offsetTop) }px`;
        }

        container.style.width = `${ Math.round(rect.right - rect.left) }px`; // outerWidth

        if (!resize) {
            container.show();

            if (!container.maxHeight) {
                container.maxHeight = parseInt(getComputedStyle(container).maxHeight);
            }

            if (!container.suggestionHeight) {
                let first = container.querySelector(`.${ options.itemClass }`);

                // when not passed a single item
                if (first !== null) {
                    container.suggestionHeight = first.offsetHeight;
                }
            }

            if (container.suggestionHeight)
                if (!next) {
                    container.scrollTop = 0;
                } else {
                    let { scrollTop } = container;
                    let selectedTop = next.getBoundingClientRect().top - container.getBoundingClientRect().top;

                    if (selectedTop + container.suggestionHeight - container.maxHeight > 0) {
                        container.scrollTop = selectedTop + container.suggestionHeight + scrollTop - container.maxHeight;
                    } else if (selectedTop < 0) {
                        container.scrollTop = selectedTop + scrollTop;
                    }
                }
        }
    },
    _focusHandler: function (event) {
        this.input.lastValue = '\n';
        this._keyupHandler(event);
    },
    _blurHandler: function () {
        const overSb = document.querySelector(`.${ this.options.containerClass }:hover`);

        let { input } = this;
        let { container } = this;

        if (!overSb) {
            input.lastValue = input.value;

            setTimeout(function () {
                container.hide();
            }, 0);
        } else if (input !== document.activeElement) {
            setTimeout(function () {
                input.focus();
            }, 0);
        }
    },
    _keydownHandler: function (event) {
        const button = event.code;
        let { input, options, container } = this;

        if ((button === 'ArrowDown' || button === 'ArrowUp') && container.innerHTML) {
            let next;
            const selected = container.querySelector(`.${ options.itemClass }.selected`);

            if (!selected) {
                let items = container.querySelectorAll(`.${ options.itemClass }`);

                if (items.length > 0) {
                    next = button === 'ArrowDown' ? items[0] : items[items.length - 1]; // first : last

                    next.classList.add('selected');

                    if (options.changeValue === true) {
                        input.value = next.dataset.val;
                    }
                }
            } else {
                selected.classList.remove('selected');

                let el = selected;

                // so that you can add items to the list for which you do not need to run (example: the title for the group)
                while (el && !next) {
                    el = button === 'ArrowDown' ? el.nextElementSibling : el.previousElementSibling;

                    if (el && _matches(el, `.${ options.itemClass }`)) {
                        next = el;
                    }
                }

                if (next) {
                    next.classList.add('selected');

                    if (options.changeValue === true) {
                        input.value = next.dataset.val;
                    }
                } else {
                    input.value = input.lastValue;
                    next = 0;
                }
            }

            this._updateContainer(0, next);

            event.preventDefault();
        } else if (button === 'Escape') {
            input.value = input.lastValue;
            container.hide();
        } else if (button === 'Enter' || button === 'NumpadEnter' || button === 'Tab') {
            const selected = container.querySelector(`.${ options.itemClass }.selected`);

            if (selected && container.visible) {

                // обработка выбранного варианта
                this.options.onSelect(event, selected.dataset.val, selected);
                // не дать нажатию на кнопку пройти дальше
                event.preventDefault();

                setTimeout(function () {
                    container.hide();
                }, 0);
            }
        }
    },
    _keyupHandler: function (event) {
        const controlButtons = ['Tab', 'Enter', 'NumpadEnter', 'Escape', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];
        const button = event.code;

        if (!button || !controlButtons.includes(button)) {
            let { input, options, container, _suggest } = this;
            let { value } = input;

            input.lastValue = value;

            if (value.length >= options.minChars) {
                clearTimeout(input.timer);

                input.timer = setTimeout(function () {
                    options.source(value, _suggest);
                }, options.delay);
            } else {
                container.hide();
            }

            options.onChangeValue(event,value);
        }
    },
    destroy: function () {
        let { input } = this;

        _off(window, 'resize', this._updateContainer);
        _off(input, 'blur', this._blurHandler);
        _off(input, 'focus', this._focusHandler);
        _off(input, 'keydown', this._keydownHandler);
        _off(input, 'keyup', this._keyupHandler);

        if (input.autocompleteReserve) {
            input.autocomplete = input.autocompleteReserve;
        } else {
            input.removeAttribute('autocomplete');
        }

        this.container.remove();

        this.input = input = null;
    }
};

function _live(selector, event, callback, context) {
    context.addEventListener(event, function (event) {
        let found = false;
        let element = event.target;

        while (element && !(found = _matches(element, selector))) {
            element = element.parentElement;

            if (element === context) {
                break;
            }
        }

        if (found === true) {
            callback.call(element, event, context);
        }
    });
}

function _extend(dst, src) {
    if (dst && src) {
        for (let key in src) {
            if (src.hasOwnProperty(key)) {
                dst[key] = src[key];
            }
        }
    }

    return dst;
}

function _on(element, event, fn) {
    element.addEventListener(event, fn, {
        capture: false,
        passive: false
    });
}

function _off(element, event, fn) {
    element.removeEventListener(event, fn, {
        capture: false,
        passive: false
    });
}

/**
 * Polyfill for Element.prototype.matches
 * @param element
 * @param selector
 * @private
 */
function _matches(element, selector) {
    let matches = Element.prototype.matches ||
        Element.prototype.matchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.msMatchesSelector;

    return matches.call(element, selector);
}

/**
 * Create JcAutocomplete instance
 * @param {HTMLElement} input
 * @param {Object} [options]
 */
JcAutocomplete.create = function (input, options) {
    return new JcAutocomplete(input, options);
};

// Export
export default JcAutocomplete;
