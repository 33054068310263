import JcAutocomplete from '../modules/jc-autocomplete.js';
import axios from 'axios';
import Cookies from "js-cookie";

// todo: нужно проверить где еще юзается jquery и выпилить его
const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

let globalCancelSuggest = null;

document.addEventListener('DOMContentLoaded', () => {
    const ENV = window.__env || {};

    //region Sticky Header

    const headerElem = document.querySelector('.header');
    const topElemHeight = document.getElementById('top-elements').offsetHeight;
    const headerStickyEnabled = ENV.headerStickyEnabled ?? true;

    if (headerStickyEnabled) {
        window.addEventListener('scroll', () => {
            headerElem.classList.toggle('stuck', window.scrollY > topElemHeight);
        });
    }

    //endregion


    // region Categories Button

    const categoryItemElems = document.querySelectorAll('[data-toggle="category"]');
    const subcategoryItemElems = document.querySelectorAll('.subcategories');

    // todo: нужно как-то сделать menu-aim
    // document.addEventListener('mouseover', event => {
    //     if (!event.target.closest('[data-toggle="category"]')) return;
    //     const categoryId = event.target.getAttribute('data-category-id');
    //
    //     console.log(categoryId, event.target);
    // });

    if (categoryItemElems && categoryItemElems.length > 0) {
        const categoriesNavbar = document.getElementById('navbar');
        const isMobile = !window.matchMedia('(min-width: 768px)').matches;

        const initialState = () => {
            subcategoryItemElems.forEach(elem => elem.classList.add('d-none'));
            categoryItemElems.forEach(elem => elem.classList.remove('hover'));
        };

        // При закрытии offcanvas скрываем все подменю, для аккуратной анимации
        categoriesNavbar.addEventListener('hide.bs.offcanvas', initialState);

        categoryItemElems.forEach(category => {
            const categoryId = category.getAttribute('data-category-id');
            const subcategoryElem = document.getElementById(`nav-subcategory-${categoryId}`);
            const eventName = isMobile ? 'click' : 'mouseover';

            category.addEventListener(eventName, event => {
                if (eventName === 'click') event.preventDefault();

                initialState();

                if (!subcategoryElem) return;

                category.classList.add('hover');
                subcategoryElem.classList.remove('d-none');
            });

            // Для мобильной версии
            // обработка клика по кнопке назад
            if (!subcategoryElem) return;

            subcategoryElem
                .querySelector('[data-dismiss="subcategory"]')
                .addEventListener('click', initialState);
        });
    }

    //endregion


    //region Cart Toggle

    const cartToggleElem = document.querySelectorAll('[data-toggle="cart"]');

    if (cartToggleElem && cartToggleElem.length > 0) {
        const cartItems = JSON.parse(window.localStorage.getItem('cartItems') ?? '[]');

        cartToggleElem.forEach(elem => {
            const id = elem.getAttribute('data-id');
            const title = elem.getAttribute('data-title');
            const price = elem.getAttribute('data-price');

            // Проверям наличие товара в корзине
            const index = cartItems.findIndex(item => item.id === id);

            const addCheckedState = function () {
                elem.classList.add('checked');
                elem.innerHTML = `
                    В корзине
                    <svg class="d-inline-block align-top me-1" width="24" height="24">
                        <use xlink:href="#icon-ok"></use>
                    </svg>
                `;

                elem.addEventListener('click', () => window.location.assign('/cart'));
            };

            const addToCart = function () {
                const evt = new StorageEvent('storage', {
                    key: 'items',
                    oldValue: 'oldValue',
                    newValue: 'newValue',
                    url: null,
                    storageArea: window.localStorage
                });

                cartItems.push({
                    id,
                    title,
                    price,
                    additional_info: '',
                    count: 1,
                    total: price,
                    checked: true,
                });

                localStorage.setItem('cartItems', JSON.stringify(cartItems));
                window.dispatchEvent(evt);

                // Удаляем обработчик добавления в корзину,
                // чтобы при переходе в корзину не прибовлялся еще один товар
                elem.removeEventListener('click', addToCart);
                addCheckedState(elem);
            };

            if (index === -1) {
                // Если товара нет в корзине вешаем обработчик добавления в корзину
                elem.addEventListener('click', addToCart);
            } else {
                // Если товар уже в корзине – выделяем его
                addCheckedState(elem);
            }
        });
    }

    //endregion


    //region Up Button

    const buttonToTopElem = document.getElementById('button-to-top');

    if (buttonToTopElem) {
        document.addEventListener('scroll', () => {
            buttonToTopElem.classList.toggle('d-md-block', window.scrollY > 400);
        });

        buttonToTopElem.addEventListener('click', (event) => {
            event.preventDefault();

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    }

    //endregion


    //region Autocomplete

    const autocompleteElem = document.getElementById('qtext');
    const formGlobalSearch = document.getElementById('form_search');
    let globalSearchClear = document.getElementById('global_search_clear')

    const clearGlobalSearch = () => {
        if(autocompleteElem){
            autocompleteElem.value = '';
        }
        testGlobalsearchValue();
        autocompleteElem.focus();
        //formGlobalSearch.submit();
    }

    function hideClearButton(){
        let closeBtnVisible = true;

        if(globalSearchClear.classList.contains('d-none')){
            closeBtnVisible = false;
        }

        if(closeBtnVisible){
            globalSearchClear.classList.add('d-none');
            autocompleteElem.style['padding-right'] = '2.1em';
        }
    }

    function showClearButton(){
        let closeBtnVisible = true;

        if(globalSearchClear.classList.contains('d-none')){
            closeBtnVisible = false;
        }

        if(!closeBtnVisible){
            globalSearchClear.classList.remove('d-none');
            autocompleteElem.style['padding-right'] = '3.7em';
        }
    }

    const testGlobalsearchValue = () => {

        if(autocompleteElem.value !== ''){
            showClearButton();
        }else{
            hideClearButton();
        }
    }


    if(globalSearchClear){
        globalSearchClear.addEventListener('click',function click (ev) {
            clearGlobalSearch();

        })
    }

    const handleSource = async function (term, response) {

        const formData = new FormData();
        formData.append('str', term.trim());

        if(globalCancelSuggest){
            globalCancelSuggest.cancel('Cancel for future request');
        }


        let preloadSuggestElem = document.createElement('div');
        preloadSuggestElem.classList.add('spinner-border', 'spinner-border-sm', 'opacity-50', 'position-absolute', 'end-0', 'inset-y-0', 'my-auto', );
        preloadSuggestElem.style['margin-right'] = '4.9rem';
        formGlobalSearch.append(preloadSuggestElem);

        globalCancelSuggest = axios.CancelToken.source();

        let config = {cancelToken: globalCancelSuggest.token};

        try {
            const res = await axios.post('/ajax/products/solr/suggest', formData, config)
                .catch((res) => {
                    //console.log('Error res', res);
                    preloadSuggestElem.remove();
                });
            //response(res.data.data);
            response(res.data);
        } catch (error) {
            // console.log(error);
        }

        preloadSuggestElem.remove();
    };

    const renderItem = function (item, search) {
        /** todo: зачем тут с сервера возвращается <b>?
         *  разве с сервера не должны приходить чистые данные? выделить найденный поддекст проще на фронте
         *  а вообще там приходит заголовок + описание, с переносами строк, со спискам и т.д. странно это
         */

        /*
        * <b> </b> возвращается потому что так сам солр в подсказках выделяет вхождение.
        * если это просто вхождение - то можно и на фронте выделить, а если была коррекция ошибок или найдено похожее слово,
        * то проще от солр получить то что он подобрал
        * */


        const computedItem = item.trim().replaceAll(/\r?\n/g, '&nbsp;').replaceAll(' ', '&nbsp;');

        const computedValue = computedItem
            .replace(/<b>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/"/gi, '&quot;');

        return `
            <div class="${this.itemClass}" data-val="${computedValue}">
                <div class="text-truncate">${computedItem}</div>
            </div>
        `;
    };

    const renderHistory = function (item,search){

        const computedItem = item.trim().replaceAll(/\r?\n/g, '&nbsp;').replaceAll(' ', '&nbsp;');
        const computedValue = computedItem
            .replace(/<b>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/"/gi, '&quot;');

        return `
            <div class="${this.itemClass} history" data-val="${computedValue}">
                <div class="text-truncate">${computedItem}</div>
            </div>
            `;
    };


    const renderCategory = function (item, search) {
        item.value = item?.value?.trim().replaceAll(/\r?\n/g, "&nbsp;").replaceAll(' ', "&nbsp;");
        search = search?.replace(/[^\w\s]/g, '\\$&');

        let cat = '';
        if(item.category !== ''){
            cat = `<span class="text-muted"><span class="ms-2 me-2">/</span> ${item.category}</span>`;
        }

        let re = new RegExp("(".concat(search?.split(' ').join('|'), ")"), 'gi');

        return `
            <div 
                class="${this.itemClass} category" 
                data-type="cat" 
                data-url="${item.url}" 
                data-val="${item?.value?.replace(/"/gi, '&quot;').replace(/<b>/g, '').replace(/<\/b>/g, '')}">
                ${item?.value?.replace(re, '$1')} ${cat}
            </div>
        `;
    };


    const handleSelect = function (event, term, item) {

        // переход на категорию, если есть нужные данные в выбранном элементе
        let attrDataType = item.getAttribute('data-type');
        //console.log('attrDataType',attrDataType);
        if(attrDataType ==='cat') {
            let url = item.getAttribute('data-url');
            if (url) {
                //console.log('before assign',url);
                location.assign(url);
                return;
            }
        }
        //console.log('before submit');
        autocompleteElem.value = term;

        ///document.getElementById('form_search').submit();
    };



    if (autocompleteElem) {
        JcAutocomplete.create(autocompleteElem, {
            minChars: 1,
            cache: 0,
            source: handleSource,
            renderItem: renderItem,
            renderHistory: renderHistory,
            renderCategory: renderCategory,
            onSelect: handleSelect,
            onChangeValue: testGlobalsearchValue,
            changeValue: false,
            delay:40
        });
    }

    testGlobalsearchValue();
    //endregion

});
